/** @jsx jsx */
import React from "react"
import { Box, Flex, jsx } from "theme-ui"
import { Link as TLink } from "theme-ui"
import { Link } from "gatsby"

type PostLinkProps = {
  slug: string
  title: string
  text: string
  textAlign?: string
};

const PostLink = ({ slug, title, text, textAlign }: PostLinkProps) => (
    <Box sx={{textAlign: textAlign ? textAlign : 'initial'}}>
      <Box>{text}</Box>
      <Box>
        <TLink as={Link} to={slug} sx={{ fontSize: [1, 2, 3] }}>
          {title}
        </TLink>
      </Box>
    </Box>
);

export default PostLink