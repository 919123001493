/** @jsx jsx */
import { jsx, Heading, Grid } from "theme-ui"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Layout from "./layout";
import ItemTags from "./item-tags";
import SEO from "./seo"
import PostLink from "./post-link";
import { Disqus } from 'gatsby-plugin-disqus';
import { BlogPosting } from "schema-dts";
import { helmetJsonLdProp } from "react-schemaorg";
import useSiteMetadata from "../hooks/use-site-metadata";

type PostProps = {
    data: {
        post: {
            slug: string
            title: string
            date: string
            dateRaw: string
            tags?: {
                name: string
                slug: string
            }[]
            description?: string
            canonicalUrl?: string
            body: string
            excerpt: string
            timeToRead?: number
            banner?: {
                childImageSharp: {
                    resize: {
                        src: string
                    }
                }
            }
        }
    },
    pageContext: {
        previousPost?: {
            slug: string
            title: string
        },
        nextPost?: {
            slug: string
            title: string
        }
    }
}

const px = [`32px`, `16px`, `8px`, `4px`]
const shadow = px.map((v) => `rgba(0, 0, 0, 0.15) 0px ${v} ${v} 0px`)

const Post = ({ data: { post }, pageContext: {previousPost, nextPost} }: PostProps) => {
    const { authorName, siteUrl } = useSiteMetadata();

    const jsonLd = helmetJsonLdProp<BlogPosting>({
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": post.title,
        "datePublished": post.dateRaw,
        "dateModified": post.dateRaw,
        "author": [{
          "@type": "Person",
          "name": authorName,
          "url": `${siteUrl}${post.slug}`
        }]
    });

    return (
      <Layout>
          <SEO
              title={post.title}
              description={post.description ? post.description : post.excerpt}
              image={post.banner ? post.banner.childImageSharp.resize.src : undefined}
              pathname={post.slug}
              canonicalUrl={post.canonicalUrl}
              script={jsonLd}
          />
          <Heading as="h1" variant="styles.h1">
              {post.title}
          </Heading>
          <p sx={{ color: `secondary`, mt: 3, a: { color: `secondary` }, fontSize: [1, 1, 2] }}>
              {post.date}
              {post.tags && (
                  <React.Fragment>
                      {` — `}
                      <ItemTags tags={post.tags} />
                  </React.Fragment>
              )}
              {post.timeToRead && ` — `}
              {post.timeToRead && <span>{post.timeToRead} min read</span>}
          </p>
          <section
              sx={{
                  my: 4,
                  ".gatsby-resp-image-wrapper": { my: [4, 4, 5], boxShadow: shadow.join(`, `) },
                  variant: `layout.content`,
              }}
          >
              <MDXRenderer>{post.body}</MDXRenderer>
          </section>

          <Grid columns={[2]}>
              { previousPost && (<PostLink slug={previousPost.slug} title={previousPost.title} text='Previous' />) }
              { nextPost && (<PostLink slug={nextPost.slug} title={nextPost.title} text='Next' textAlign={previousPost ? 'right' : 'initial'} />) }
          </Grid>

          <Disqus
              config={{
                  url: post.canonicalUrl,
                  identifier: post.slug,
                  title: post.title,
              }}
          />
      </Layout>
    )
}

export default Post
